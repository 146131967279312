import { Wrapper } from "./styles"
import { HiLocationMarker } from "react-icons/hi"
import { FaWaze } from "react-icons/fa"

const Location = () => {
    return (
        <Wrapper>
            <div style={{width: "90%", display: "flex", alignItems: 'center', margin: "5px 0"}}><HiLocationMarker color="#D5FF5C" fontSize={"1.4em"}/> &nbsp;&nbsp; <span style={{fontSize: "0.8em"}}>Rua Artur de Souza Marques, 270 - Morumbi</span></div>
            {/* <div style={{width: "90%", display: "flex", alignItems: 'center', margin: "5px 0"}}><FaWaze color="#42d4ff" fontSize={"1.4em"}/> &nbsp;&nbsp; <span style={{fontSize: "0.8em"}}>Cenesp Estacionamento 18</span></div> */}
        </Wrapper>
    )
}

export default Location
import styled from "styled-components"

const Wrapper = styled.div`
    width: 85%;
    min-height: 70px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin-bottom: 20px;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export {
    Wrapper
}
import { useQuery } from 'react-query'

import {
    Container,
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
    Shadow,
    Button,
    // Arrow1,
    // Arrow2,
    // Arrow3
} from './styles'

import { BsCalendarEvent, BsSpotify } from 'react-icons/bs';
import { GiTicket } from 'react-icons/gi';

import { RiInstagramFill } from 'react-icons/ri'

import client from "../../../assets/service/client";
import Loader from '../../../assets/components/loader/Loader';
import Gallery from '../../../assets/components/gallery/Gallery';
import { Link } from 'react-router-dom';
import Action from '../../action/Action';
import Location from '../../../assets/components/location/Location';

const EventList = ({isVisible}) => {
    const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

    const getEventList = async () => {
        return await client.get(`event/organizer/${process.env.REACT_APP_ORGANIZER_ID}`)
        .then(res => {
            return res.data.events
        })
        .catch(err => err.message)
    }

    const parseDate = (date) => {
        let d = new window.Date(date);
        return d.toLocaleString("pt-br", {
            month: "short", day:"numeric", weekday: "short"
        });
    }

    const parseTime = (date) => {
        let d = new window.Date(date);
        return d.toLocaleString("pt-br", {
            hour: "numeric", minute: "numeric"
        });
    }

    
    const { isLoading, isError, data: events, error } = useQuery('events', getEventList);

    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <ListContainer className='no-scroll'>
                { 
                isLoading ? <Loader /> :
                isError ? <>ERROR</> :
                !events.length ? <>Nenhum evento encontrado</> :
                events.map(event => {
                    let link = event.alias.toString();
                    let imgKey = event.backgroundArtImage.key;
                    return (
                        <Wrapper key={event.id}>
                            <Image to={`/events/${link}`} img={`${process.env.REACT_APP_S3_URL}/${imgKey}`} />
                            <DescriptionWrapper>
                                <Description to={`/events/${link}`}>
                                    <Title>{event.name}</Title>
                                    <Date><BsCalendarEvent style={{color: '#fff'}}/> &nbsp; {parseDate(event.date)}</Date>
                                </Description>
                                <Link to={`/events/${link}`} style={{display: "flex", justifyContent: "space-between", width: "75px", textDecoration: "none", color: 'white'}}>
                                    <span style={{fontSize: "1em"}}><GiTicket /></span>
                                    <span style={{fontSize: "0.5em", alignSelf: "flex-end", textAlign: "center"}}>Mais informações</span>
                                </Link>
                            </DescriptionWrapper>
                        </Wrapper>
                    )
                }) 
                }
                <Button to='balaco/tickets'>Garanta seu Ingresso</Button>
                <Location />
                <div style={{width: "85%"}}>
                {/* <iframe style={{"border-radius" : "12px"}} src="https://open.spotify.com/embed/playlist/1UVMVhWndhAbWIy5avhGyc?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
                {/* <iframe style={{"border-radius" : "12px"}} src="https://open.spotify.com/embed/playlist/24vaZKqJEXYUllrpVO5RTb?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
                <iframe style={{"border-radius" : "12px"}} src="https://open.spotify.com/embed/playlist/5oXCUCy3gnLOQuoipoIpeF?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </div>
                <div style={{width: "85%"}}>
                {/* <Gallery items={[
                    "18", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
                    "11", "12", "13", "14", "15", "16", "17", "19", "20", "21"
                ]}/> */}
                </div>
                <div style={{minHeight: "20px"}} />
                {/* <Action /> */}
            </ListContainer>
            {/* <Button to='reverse'>Garanta seu Ingresso</Button>
            <Arrow1 />
            <Arrow2 />
            <Arrow3 /> */}
            {/* <Shadow /> */}
        </Container>
    )
}

export default EventList
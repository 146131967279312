import styled from 'styled-components';

// import rvr from '../../images/RVR-113.jpg'

// import instagramRedirect from '../../images/insta_gif.gif'

import bgy from '../../../assets/images/bgy.png'

const Container = styled.div`
    position: fixed;    
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${bgy});
    background-size: cover;
    background-repeat: no-repeat;

    z-index: 70;
`

const Modal = styled.div`
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

// const Logo = styled.div`
//     height: calc(162px * 0.7);
//     width: calc(273px * 0.7);

//     background-image: url(${logo});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
// `

// const InstagramBrowserRedirectGif = styled.div`
//     height: calc(791px * 0.6);
//     width: calc(380px * 0.6);

//     background-image: url(${instagramRedirect});
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
// `

const Form = styled.div`
    transition: 1s ease all;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 400px;
    max-height: 90%;
    width: 80%;
    min-width: 270px;
    max-width: 400px;

    background-color: rgba(0, 0, 0, 0.5);

    border-radius: 5px;
    padding: 20px 10px;
`

export {
    Container,
    Modal,
    // InstagramBrowserRedirectGif,
    Form
}
import { Page,
    Area,
    Text,
    ButtonArea,
    BigButtonArea,
    Overlay,
    ButtonLink,
} from "./styles";

import { useNavigate } from 'react-router-dom'

import cartaz from '../../assets/images/cartaz.png'

const Home = () => {

    const navigate = useNavigate()

    return (
    <Page>
       <video style={{
            position: "absolute",
            top: "0",
            left: "0",
            display: "block",
            width: "100vw",
            height: "100vh",
            "OobjectFit": "cover",
            "objectFit": "cover",
        }} src="https://gandaya.b-cdn.net/balacobaca_11-nov.mp4" autoPlay loop muted playsInline></video>
        <Overlay />
        <Area>
            <Text />
            {/* <ButtonArea>
                <BigButtonArea>
                    <ButtonLink to="/events">Garanta seu Ingresso</ButtonLink>
                </BigButtonArea>
            </ButtonArea> */}
            <div
                style={{
                    backgroundImage: `url(${cartaz})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "180px",
                    height: "80px",
                    position: "relative",
                    cursor: "pointer"
                }}
                onClick={() => {
                    navigate('/events/balaco/tickets')
                }}
            >
                <span
                    style={{
                        fontFamily: "LifelogoEasy",
                        fontSize: "2rem",
                        transform: "rotate(-8deg) translate(-50%, -50%)",
                        position: "absolute",
                        color: "#FCFAE1",
                        left: "50%",
                        top: "37%"
                    }}
                >ingressos</span>
            </div>
        </Area>
    </Page>
    )
}

export default Home;
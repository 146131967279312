import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { BsDashLg, BsInfoLg, BsPlusLg } from "react-icons/bs";

import {
    Container,
    ListContainer, 
    ToggleMarketPlace,
    Item, 
    Wrapper, 
    WrapperLink,
    Description, 
    Price, 
    SellerImage,
    ImageWrapper,
    Informative,
    TicketInfo,
    QuantitySelector,
    Button,
    EventImage,
    InfoIcon,
    ErrorIcon
} from './styles';

import logo from '../../../assets/images/logo.png';
import banana from '../../../assets/images/banana.png';
import grape from '../../../assets/images/grape.png';
import bg_fruits from '../../../assets/images/bg-fruits.png';
import bg from '../../../assets/images/bg.png'

import client from "../../../assets/service/client";
import { useQuery } from "react-query";
import Loader from "../../../assets/components/loader/Loader";
import { AnimatePresence } from "framer-motion";
import InfoModal from "./modal/InfoModal";

const TicketList = () => {
    let [showTickets, setShowTickets] = useState(true);
    let [showOrders, setShowOrders] = useState(false);
    let [showInfo, setShowInfo] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let { alias } = useParams();

    let promo = searchParams.get("promo");
    let error = searchParams.get("error");

    let [quantity, setQuantity] = useState({});
    let [products, setProducts] = useState([]);

    const getQuantity = (ticketSpecId) => {
        return quantity[ticketSpecId] || 0;
    }

    const incrementQuantity = (ticketSpecId, multiplier, orderLimit) => {
        let delta = multiplier || 1
        let limit = orderLimit || Infinity

        let updatedQuantity = {}
        updatedQuantity[ticketSpecId] = quantity[ticketSpecId] ? quantity[ticketSpecId] + delta : delta
        updatedQuantity[ticketSpecId] = updatedQuantity[ticketSpecId] > limit ? limit : updatedQuantity[ticketSpecId]

        setQuantity(quantity => ({
            ...quantity,
            ...updatedQuantity
        }))
    }

    const decrementQuantity = (ticketSpecId, multiplier) => {
        let delta = multiplier || 1
        let updatedQuantity = {}
        updatedQuantity[ticketSpecId] = quantity[ticketSpecId] && quantity[ticketSpecId] - delta

        setQuantity(quantity => ({
            ...quantity,
            ...updatedQuantity
        }))
    }

    const parseProducts = (tickets, promo) => {
        let state = []
        for (let ticketSpecId of Object.keys(quantity)) {
            if (quantity[ticketSpecId]) {
                let ticketSpec = tickets.filter(t => t.id == ticketSpecId)[0]
                state.push({
                    ...ticketSpec, quantity: quantity[ticketSpecId], codeUsed: promo
                })
            }
        } 

        setProducts([...state]);
    }

    useEffect(() => {
        if (products.length) {
            document.getElementById("link_to_checkout").click()
        }
    }, [products])

    const getEventByAlias = async () => {
        return await client.get(`event/alias/${alias}`)
        .then(res => {
            if (res.data.event.pixelId) {
                window.fbq('init', `${res.data.event.pixelId}`);
                window.fbq('trackSingle', `${res.data.event.pixelId}`, 'ViewContent')
                window.fbq('trackSingle', process.env.REACT_APP_PIXEL_ID, 'ViewContent');
            }
            return(res.data.event);
        })
        .catch(err => err)
    }

    const getActiveTickets = async (eventId) => {
        return await client.get(`event/${eventId}`)
        .then(res => {
            return(res.data.event.activeTicketSpecs);
        })
        .catch(err => err)
    }

    const getPromoTickets = async (eventId) => {
        if (promo) {
            return await client.get(`ticket/event/${eventId}/promo/${promo}`)
            .then(res => {
                return(res.data.ticketSpecs);
            })
            .catch(err => err)
        } else return null
    }

    const getEventOrders = async (eventId) => {
        return await client.get(`event/${eventId}/orders`)
        .then(res => {
            return(res.data.orders);
        })
        .catch(err => err)
    }

    const { isLoading: isEventLoading, isError: isEventError, data: event, error: eventError } = useQuery(['event', alias], getEventByAlias, { keepPreviousData: false });
    
    const { isLoading: isTicketsLoading, isError: isTicketsError, data: tickets, error: ticketsError } = useQuery(['eventTickets', alias], () => getActiveTickets(event.id), {enabled: (!!showTickets) && (!!event), keepPreviousData: false});

    // const tickets = [
    //     {
    //         "id": 935,
    //         "price": "100.00",
    //         "pixFee": "10.00",
    //         "cardFee": "10.00",
    //         "description": "Teste",
    //         "publicDescription": null,
    //         "numberOfAvailableTickets": 100,
    //         "numberOfSoldTickets": 0,
    //         "numberOfReservedTickets": 0,
    //         "active": true,
    //         "promo": null,
    //         "multiplier": null,
    //         "orderLimit": "2",
    //         "userLimit": null,
    //         "createdAt": "2023-10-21T00:04:03.000Z",
    //         "updatedAt": "2023-10-21T00:04:06.000Z",
    //         "artImageId": null,
    //         "batchId": 844,
    //         "followerId": null
    //       },
    //       {
    //         "id": 936,
    //         "price": "60.00",
    //         "pixFee": "10.00",
    //         "cardFee": "10.00",
    //         "description": "Teste 2",
    //         "publicDescription": null,
    //         "numberOfAvailableTickets": 100,
    //         "numberOfSoldTickets": 0,
    //         "numberOfReservedTickets": 0,
    //         "active": true,
    //         "promo": null,
    //         "multiplier": null,
    //         "orderLimit": "1",
    //         "userLimit": null,
    //         "createdAt": "2023-10-21T00:04:03.000Z",
    //         "updatedAt": "2023-10-21T00:04:06.000Z",
    //         "artImageId": null,
    //         "batchId": 844,
    //         "followerId": null
    //       }
    // ]
    
    // const isTicketsLoading = false

    const { isLoading: isPromoLoading, isError: isPromoError, data: promoTickets, error: promoError } = useQuery(['eventTicketsPromo', alias], () => getPromoTickets(event.id), {enabled: (!!showTickets) && (!!event), keepPreviousData: false});
    
    // const promoTickets = [
    //     {
    //       "id": 937,
    //       "price": "60.00",
    //       "pixFee": "10.00",
    //       "cardFee": "10.00",
    //       "description": "Promo",
    //       "publicDescription": null,
    //       "numberOfAvailableTickets": 100,
    //       "numberOfSoldTickets": 0,
    //       "numberOfReservedTickets": 0,
    //       "active": true,
    //       "promo": null,
    //       "multiplier": null,
    //       "orderLimit": "1",
    //       "userLimit": null,
    //       "createdAt": "2023-10-21T00:04:03.000Z",
    //       "updatedAt": "2023-10-21T00:04:06.000Z",
    //       "artImageId": null,
    //       "batchId": 844,
    //       "followerId": null
    //     }
    // ]

    const { isLoading: isOrdersLoading, isError: isOrdersError, data: orders, error: ordersError } = useQuery(['eventOrders', alias], () => getEventOrders(event.id), {enabled: (!!showOrders) && (!!event), keepPreviousData: false});

    if (isEventLoading || (showTickets && isTicketsLoading) || (showOrders && isOrdersLoading)) {
        return (
            <Container>
                <Loader />
            </Container>
        )
    }

    return (
        // <div
        //     style={{
        //         backgroundImage: `url(${bg})`,
        //         backgroundSize: "cover",
        //         backgroundRepeat: "no-repeat",
        //         width: "100%",
        //         height: "100%",
        //         display: "flex",
        //         justifyContent: "center"
        //     }}
        // >
        <Container className="no-scroll" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            {/* { showInfo && <InfoModal onClose={() => setShowInfo(false)} /> } */}
            {/* <InfoIcon onClick={() => setShowInfo(true)} ><BsInfoLg style={{fontSize: '1.2em'}} /></InfoIcon> */}
            <EventImage img={logo} />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "40px"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly"
                    }}
                >
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                            }} 
                        >
                            <span style={{fontSize:"1.5rem", marginRight: "6px"}}>11</span>
                            <span style={{fontSize:"0.8rem", marginRight: "6px", marginBottom: "3px"}}>de</span>
                            <span style={{fontSize:"1.5rem"}}>novembro</span>
                        </div>
                        <span style={{fontFamily: "LifelogoEasy", fontSize: "2rem"}}>OPEN BAR</span>
                        <div>
                            <span style={{fontSize:"0.8rem", marginRight: "6px", marginBottom: "3px"}}>ÀS</span>
                            <span style={{fontSize:"1.5rem"}}>21</span>
                            <span style={{fontSize:"1rem"}}>h</span>
                            <span style={{fontSize:"1.5rem"}}>00</span>
                        </div>
                    </div>
                    <div
                        style={{
                            backgroundImage: `url(${banana})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "120px",
                            height: "120px",
                            position: "relative"
                        }}
                    />
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly"
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${grape})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "140px",
                            height: "140px",
                            position: "relative"
                        }}
                    />
                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <span
                            style={{
                                fontSize: "1.2rem",
                                textAlign: "end"
                            }}
                        >BALACOBACA</span>
                        <span
                            style={{
                                fontSize: "0.8rem",
                                textAlign: "end"
                            }}
                        >
                        É a bagunça tropical.
                        </span>
                        <span
                            style={{
                                fontSize: "0.8rem",
                                textAlign: "end"
                            }}
                        >
                        É onde toda menina baiana tem encantos que Deus dá.</span>
                        <span
                            style={{
                                fontSize: "0.8rem",
                                textAlign: "end"
                            }}
                        >
                        Rua Artur de Souza Marques, 270</span>
                    </div>
                </div>
            </div>

            <AnimatePresence exitBeforeEnter={true} initial={false}>
            {
                error && <div key="randomkey" style={{fontSize: "0.8em", width: "80%", textAlign: "center", marginBottom: "16px", position: 'absolute', bottom: "10px", backgroundColor: "rgba(0,0,0,0.5)", borderRadius: "4px", padding: "10px 20px", backdropFilter: "blur(4px)"}}>Os ingressos selecionados não estão mais disponíveis. Por favor, selecione novamente seus ingressos</div>
            }
            {
                showTickets && (tickets || promoTickets) && (
                    <>
                    <ListContainer key="event-tickets" initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }} transition={{ duration: 0.2 }}>
                    {
                    !!tickets && !!tickets?.length &&
                        tickets.map(ticket => {
                            return (
                                <Wrapper key={ticket.id}>
                                    <Informative>
                                        <TicketInfo>
                                            <Description>{
                                            // ticket.description.includes("Masc") ? "Ingresso Masc ~ Open Bar" : 
                                            // ticket.description.includes("Fem") ? "Ingresso Fem ~ Open Bar" :
                                            ticket.description
                                            }</Description>
                                            <Price>R${ticket.price}</Price>
                                        </TicketInfo>
                                        <QuantitySelector>
                                            <BsDashLg onClick={() => decrementQuantity(ticket.id, ticket.multiplier)} style={{"fontSize": "0.8em", "color": "#fff"}}/> 
                                            <span style={{color: "#fff", fontSize: "1.5rem", filter: "drop-shadow(1.5px 1.5px 0px #BB4E04)"}}>{getQuantity(ticket.id)}</span>
                                            <BsPlusLg onClick={() => incrementQuantity(ticket.id, ticket.multiplier, ticket.orderLimit)} style={{"fontSize": "0.8em", "color": "#fff"}}/>
                                        </QuantitySelector>
                                    </Informative>
                                </Wrapper>
                            )
                        })
                    }
                    {
                    !!promoTickets && !!promoTickets?.length &&
                        promoTickets.map(ticket => {
                            if (!!tickets && !!tickets?.length && tickets.map(t => t.id).includes(ticket.id)) { return <></> }
                            return (
                                <Wrapper key={ticket.id}>
                                    <Informative>
                                        <TicketInfo>
                                            <Description>{ticket.description}</Description>
                                            <Price>R${ticket.price}</Price>
                                        </TicketInfo>
                                        <QuantitySelector>
                                            <BsDashLg onClick={() => decrementQuantity(ticket.id, ticket.multiplier)} style={{"fontSize": "0.8em", "color": "#fff"}}/> 
                                            <span style={{color: "#fff", fontSize: "1.5rem", filter: "drop-shadow(1.5px 1.5px 0px #BB4E04)"}}>{getQuantity(ticket.id)}</span>
                                            <BsPlusLg onClick={() => incrementQuantity(ticket.id, ticket.multiplier, ticket.orderLimit)} style={{"fontSize": "0.8em", "color": "#fff"}}/>
                                        </QuantitySelector>
                                    </Informative>
                                </Wrapper>
                            )
                        })
                    }
                    { 
                    !tickets?.length && !promoTickets?.length && 
                    <div style={{width: "90%", height: "100%", minHeight: "200px", display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center"}}>
                        <div style={{width: "100%", height: "70%", maxHeight: "400px", display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center"}}>
                            {/* <ErrorIcon /> */}
                            <span style={{fontSize: "0.8em"}}>Início das vendas 01/11 às 12h</span>
                            {/* <span style={{fontSize: "0.8em"}}>Já quer entrar no clima?</span> */}
                            {/* <span style={{fontSize: "0.8em"}}>Clique <Link to="/feed">aqui</Link> para ver as fotos da última edição.</span> */}
                        </div>
                    </div> 
                    }
                    </ListContainer>
                    <Button onClick={() => parseProducts(promoTickets?.length ? [...tickets, ...promoTickets] : tickets, promo)}>
                        <span style={{filter: "drop-shadow(1.5px 1.5px 0px #BB4E04)"}}>Continuar</span>
                    </Button>
                    <Link id="link_to_checkout" style={{"display": "none"}} to={`/events/${alias}/tickets/checkout`} state={{ products }}></Link>
                    </>
                )
            }
            {
                showOrders && orders && (
                    <ListContainer key="event-orders" initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 300, opacity: 0 }} transition={{ duration: 0.2 }}>
                    {
                    orders.length ?
                        orders.map(order => {
                            return (
                                <WrapperLink to={`/events/${event.id}/order/${order.id}/checkout`} key={order.id}>
                                    <Informative>
                                    <ImageWrapper>
                                        <SellerImage img={`${process.env.REACT_APP_S3_URL}/${order.seller.profileImage.key}`}/>
                                    </ImageWrapper>
                                    <Description>{order.ticket.ticketSpec.description}</Description>
                                    <Price>R${order.price}</Price>
                                    </Informative>
                                </WrapperLink>
                            )
                        })
                    :
                        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <span>Não Há Ingressos Disponíveis</span>
                        </div>
                    }
                    </ListContainer>
                )
            }

            <div style={{width: "90%"}}>
            <iframe title="spotify" src="https://open.spotify.com/embed/playlist/4QtfqogN91StaVM6AgoIk0?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>

            <div
                style={{
                    backgroundImage: `url(${bg_fruits})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    paddingBottom: "35%",
                    // marginBottom: "50px"
                }}
            />
            
            </AnimatePresence>
            
        </Container>
        // </div>
    )
}

export default TicketList
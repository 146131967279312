import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import logo from '../../assets/images/logo.png'

const Page = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-weight: 700;
`

const Area = styled.div`
    height: 65%;
    width: 310px;

    // min-height: 350px;
    // min-width: 300px;

    // width: 90%;
    // height: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    z-index: 2;
`

const Text = styled.div`
    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 120px;
    width: 200px;
`

const ButtonArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 55%;
    width: 85%;
`

const BigButtonArea = styled.div`
    height: 60%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // font-family: 'Aicho', sans-serif;
`

const ButtonLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 90%;
    height: 45%;
    border-radius: 5px;
    color: white;

    border: 3px solid white;

    box-shadow: 3px 3px 0 0 black, 6px 6px 0 0 white, 9px 9px 0 0 black; //, 10px 10px 0 0 white;

    transition: 0.3s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
    }

    &:hover {
        box-shadow: 0 0 0 0 white, 0 0 0 0 black;
    }

    cursor: pointer;

    text-decoration: none;
`

const Overlay = styled.div`
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.55);
    z-index:1;
`

const LoadContainer = styled.div`
    position: fixed;

    top: 0; 
    right: 0;
    height: 100vh;
    width: 100vw;

    background-color: rgba(255, 255, 255, 0.99);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 20;
`

export {
    Page,
    Area,
    Text,
    ButtonArea,
    BigButtonArea,
    ButtonLink,
    Overlay,
    LoadContainer
}
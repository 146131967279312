import styled from 'styled-components';

const Wrapper = styled.div`
    width: calc(100% - 18px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;

    background-color: #fff;

    border-radius: 15px;

    border: 3px solid white;

    // box-shadow: 3px 3px 0 0 black, 6px 6px 0 0 white, 9px 9px 0 0 black; //, 10px 10px 0 0 white;

    transition: 0.3s ease all;
    &:active {
        transform: scale(1.1) rotate(3deg);
        // box-shadow: 0 0 0 0 white, 0 0 0 0 black;
    }

    &:hover {
        // box-shadow: 0 0 0 0 white, 0 0 0 0 black;
    }
`

const Image = styled.div`
    width: 100%;
    padding-bottom: 70%;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 15px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 400px;
    position: relative;
`

const Television = styled.img`
    z-index: 100;
    position: absolute;
`

const SwiperContainer = styled.div`
    width: 300px;
    margin-bottom: 75px;
    z-index: -4;
    position: absolute;
`

export {
    Wrapper,
    Image,
    Container,
    Television,
    SwiperContainer
}